import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import I18n from 'i18n-js';
import Moment from 'react-moment';

import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import AvainiaCore from 'avainia-core-api';
import MaterialOfferIntro from './MaterialOfferIntro.js';

//Partials
import MaterialOfferGeneralInformation from '../../partials/MaterialOfferGeneralInformation/MaterialOfferGeneralInformation.js';
import MaterialOfferChoiceListing from '../../partials/MaterialOfferChoiceListing/MaterialOfferChoiceListing.js';

//Icons
import Icon from '@chakra-ui/icon';
import { BsArrowBarUp, BsFileEarmarkText } from 'react-icons/bs';

//Chakras
import { Heading, Box, Text, Link } from '@chakra-ui/layout';
import { Button, Alert, AlertTitle, Flex, ButtonGroup, Center, Spinner } from "@chakra-ui/react";

class MaterialFormOffer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      allComments: [],
      materialQuoteHasComments: false,
      quote: null,
      key: Math.random(),
      allPhotos: [],
    };
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    const { apartment, materialForm, project, submissionForm, quote } = this.props;
    let materialQuoteHasComments = false;

    const promises = [
      api.materialSelectionsPerSubmissionFormGet(project.id, apartment.id, submissionForm.id, 'materialSelections'),
      api.condominiumGet(apartment.condominium_id, 'condominium'),
      api.condominiumApartmentTemplateGet(apartment.condominium_id, apartment.apartment_template_id, 'apartmentTemplate'),
      api.getApartmentUsers(apartment.condominium_id, apartment.id, 'apartmentUsers'),
      api.materialQuoteCommentsGet(quote.id, 'materialQuoteComments'),
      api.materialFormSubmissionGet(apartment.id, materialForm.id, 'submissionForm'),
      api.materialQuotePhotosGet(project.id, quote.id, 'materialQuotePhotos'),
      api.materialQuoteRowsGet(quote.id, 'materialQuoteRows'),
    ];

    Promise.all(promises).then((data) => {
      let error = false;
      let materialSelections;
      let condominium;
      let apartmentTemplate;
      let apartmentUsers;
      let materialQuoteComments;
      let submissionForm;
      let materialQuotePhotos;
      let materialQuoteRows;

      data.forEach((x) => {
        if (error) { return; }
        if (x.materialSelections) { materialSelections = x.materialSelections; }
        if (x.condominium) { condominium = x.condominium; }
        if (x.apartmentTemplate) { apartmentTemplate = x.apartmentTemplate; }
        if (x.apartmentUsers) { apartmentUsers = x.apartmentUsers; }
        if (x.materialQuoteComments) { materialQuoteComments = x.materialQuoteComments; }
        if (x.submissionForm) { submissionForm = x.submissionForm; }
        if (x.materialQuotePhotos) { materialQuotePhotos = x.materialQuotePhotos; }
        if (x.materialQuoteRows) { materialQuoteRows = x.materialQuoteRows; }
      });

      if (error) { return this.setState({ loading: false, error }); }

      this.setState({
        materialSelections,
        condominium,
        apartmentTemplate,
        loading: false,
        apartmentUsers,
        materialQuoteComments,
        materialQuoteHasComments,
        submissionForm,
        quote: this.props.quote,
        materialQuotePhotos,
        materialQuoteRows,
      }, () => {
        const quoteCommentRows = this.state.submissionForm && this.state.submissionForm.material_quote.rows;
        quoteCommentRows && this.updateCommentStatus(quoteCommentRows);
        materialQuotePhotos && this.getAllPhotos(project.id, quote.id, materialQuotePhotos);
      })
    })
  }

  getAllPhotos = (projectId, quoteId, materialQuotePhotos) => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    materialQuotePhotos.forEach(photo => {
      api.materialQuotePhotoGet(projectId, quoteId, photo.id).then((image) => {
        if (image.error) { return this.setState({ error: image.error }); }

        this.setState((prevState) => ({
          allPhotos: { ...prevState.allPhotos, [photo.id]: { id: photo.id, image: image, name: photo.name } },
        }));
      });
    })
  }

  getTotalPrice = () => {
    const materialQuoteRows = this.state.materialQuoteRows;
    const otherTasksPrice = this.props.submissionForm.material_quote.other_tasks_price;
    const additionalRows = this.state.submissionForm.material_quote && this.state.submissionForm.material_quote.additional_rows || [];

    let price = 0;
    materialQuoteRows && materialQuoteRows.forEach((row) => {
      price += parseFloat(row.price * (row.selection_type === 'additional_product_selection' ? 1 : row.package_amount));
      price += parseFloat(row.labor_price);
    })
    price += parseFloat(otherTasksPrice)

    additionalRows && additionalRows.length > 0 && additionalRows.forEach((additionalRow) => {
      price += parseFloat(additionalRow.price) + parseFloat(additionalRow.labor_price);
    })

    return price.toFixed(2);
  }

  getWorkPriceTotal = () => {
    const materialQuoteRows = this.state.materialQuoteRows;
    const otherTasksPrice = this.props.submissionForm.material_quote.other_tasks_price;
    const additionalRows = this.state.submissionForm.material_quote && this.state.submissionForm.material_quote.additional_rows || [];

    let price = 0;
    materialQuoteRows && materialQuoteRows.forEach((row) => {
      price += parseFloat(row.labor_price);
    })

    price += parseFloat(otherTasksPrice)

    additionalRows && additionalRows.length > 0 && additionalRows.forEach((additionalRow) => {
      price += parseFloat(additionalRow.labor_price)
    })

    return price.toFixed(2);
  }

  onChangeCallback = (name, value) => {
    this.setState((prevState) => ({
      submissionFormRowComments: { ...prevState.submissionFormRowComments, [name]: value, },
    }));
  }

  saveCommentCallback = (comment) => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    const payload = {
      material_quote_id: this.props.quote.id,
      material_form_submission_id: this.props.submissionForm.id,
      comment: comment,
      source: 'owner'
    };

    api.materialQuoteCommentAdd(this.props.quote.id, payload).then((comment) => {
      if (comment.error) { return this.setState({ error: comment.error }); }

      api.materialQuoteCommentsGet(this.props.quote.id).then((materialQuoteComments) => {
        if (materialQuoteComments.error) { return this.setState({ error: materialQuoteComments.error }); }

        this.setState({
          materialQuoteComments,
          key: Math.random()
        }, () => this.updateCommentStatus())
      })
    });
  }

  saveQuoteRowCommentCallback = (comment, rowId) => {
    const { apartment, materialForm } = this.props;

    const api = new AvainiaCore(LocalStorageService.getToken);
    const payload = {
      material_quote_id: this.props.quote.id,
      material_form_submission_id: this.props.submissionForm.id,
      comment: comment,
      row_id: rowId,
      comment_source: 'owner',
      isPortal: true,
      accepted_by_owner: true,
    };

    api.apartmentMaterialQuoteRowCommentsAdd(this.props.quote.id, payload).then((comment) => {
      if (comment.error) { return this.setState({ error: comment.error }); }

      api.materialFormSubmissionGet(apartment.id, materialForm.id).then((submissionForm) => {
        if (submissionForm.error) { return this.setState({ error: submissionForm.error }); }

        this.setState({
          submissionForm,
          key: Math.random()
        }, () => {
          const quoteCommentRows = submissionForm && submissionForm.material_quote.rows;
          quoteCommentRows && this.updateCommentStatus(quoteCommentRows);
          this.updateCommentStatus(quoteCommentRows)
        })
      })
    });
  }

  accept = () => {
    const { id } = this.props.quote;
    const payload = { status: 'approved' };
    if (!window.confirm(I18n.t('materialforms.accept-quote-confirm'))) { return; }

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.apartmentMaterialquoteApprove(id, payload).then((result) => {
      if (result.error) { return this.setState({ error: result.error }); }
      window.location.reload();
    });
  }

  sendCorrections = () => {
    const { id } = this.props.quote;
    const quoteCommentRows = this.state.submissionForm && this.state.submissionForm.material_quote.rows;
    const quoteComments = this.state.materialQuoteComments.filter(comment => !comment.accepted_by_owner);

    let materialQuoteRowComments = [];

    quoteCommentRows.forEach(row => {
      (row.comments.filter((comment) => {
        if (!comment.accepted_by_owner) {
          materialQuoteRowComments.push(comment)
        }
      }))
    });

    const payload = {
      materialQuoteRowComments: materialQuoteRowComments || '',
      quoteComments,
      status: 'pending',
    };

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.apartmentMaterialquoteSendCorrections(id, payload).then((result) => {
      if (result.error) { return this.setState({ error: result.error }); }
      window.location.reload();
    });
  }

  updateCommentStatus = (quoteCommentRows = null) => {
    const materialQuoteComments = this.state.materialQuoteComments;
    let hasNewComments = false;

    if (quoteCommentRows) {
      quoteCommentRows.forEach(commentRow => {
        const lastComment = commentRow.comments[commentRow.comments.length - 1];

        if (commentRow.comments && lastComment) {
          if (lastComment.source === 'owner' && lastComment.comment !== "" && !lastComment.accepted_by_management && !hasNewComments) {
            hasNewComments = true;
          }
        }
      })
    }

    if (materialQuoteComments && materialQuoteComments.length > 0 && materialQuoteComments[materialQuoteComments.length - 1].comment) {
      const lastSubmissionFormComment = materialQuoteComments[materialQuoteComments.length - 1];
      if (lastSubmissionFormComment !== "") {
        if (lastSubmissionFormComment.source === 'owner' && !lastSubmissionFormComment.accepted_by_management && !hasNewComments) {
          hasNewComments = true;
        }
      }
    }

    this.setState({
      materialQuoteHasComments: hasNewComments,
    })
  }

  getRowPrice = (row) => {
    return row.price;
  }

  getAdditionalRowPrice = (row) => {
    return parseFloat(row.price) + parseFloat(row.labor_price);
  }

  getRowLaborPrice = (row) => {
    return row.labor_price;
  }

  openAttachment = (data) => {
    const fileURL = URL.createObjectURL(data);
    window.open(fileURL);
  };

  downloadQuotePDF = async (id, apt) => {
    try {
      const obj = { headers: { Authorization: `Bearer ${LocalStorageService.getToken()}` }};
      const apiRoute = `materialquotes/${id}/generate`;
      const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/v1/${apiRoute}`, obj);
      const name = `Tarjous_${apt.stairwell}_${apt.apartment_number}`;

      if (res.ok) {
        const binary = await res.blob();
        const fileName = name + '.pdf';
        const src = window.URL.createObjectURL(binary);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.cssText = 'display: none';
        a.href = src;
        a.download = fileName;
        window.open(src, '_blank');
        setTimeout((x) => { document.body.removeChild(a); }, 1000);
      } else if (res.status !== 410) { // 410 means dont retry
        throw new Error('failed to fetch document'); // TODO! Fatal error, do actual logging
      }
    } catch (ex) {
      console.error(ex); // TODO! Fatal error, do actual logging
    }
  }

  render() {
    const { condominium, apartmentTemplate, apartmentUsers, materialSelections, materialQuoteComments, materialQuoteHasComments, materialQuotePhotos, materialQuoteRows } = this.state;
    const { apartment, disabled } = this.props;
    const apartmentNumber = apartment.stairwell + apartment.apartment_number
    const quoteCommentRows = this.state.submissionForm && this.state.submissionForm.material_quote.rows;
    const stateSubmissionForm = this.state.submissionForm;
    const allPhotos = Object.values(this.state.allPhotos);

    return (
      <>
        {this.state.loading &&
          <Center mt={6}>
            <Spinner size="xl" />
          </Center>
        }
        { !this.state.loading &&
          <Box key={ this.state.key }>
            { this.state.quote.deadline && this.state.quote.deadline !== '0000-00-00 00:00:00' &&
                <Alert
                  placeContent={ { base: "center", md: "none" } }
                  py="0"
                  w={ { base: "100%", md: "200px" } }
                  status="error"
                  left="0"
                  p="2"
                  mt="-8"
                >
                  <AlertTitle alignSelf="end">
                    { I18n.t('materialforms.accept-offer-before') } <Moment format="DD.MM.YYYY hh:mm">{ this.state.quote.deadline }</Moment>
                  </AlertTitle>
                </Alert>
              }
            <Flex placeItems="center" position="relative">
              <Heading>{ I18n.t('materialforms.offer') }</Heading>
              <Box flex="1" textAlign="right" style={{paddingBottom: "15px"}}>

                <Button variant="lightgray-btn-dark" mr={ [0, null, 8, 4, 8] } onClick={() => this.downloadQuotePDF(this.props.quote.id, apartment)}>
                  <Icon as={ BsFileEarmarkText } w={ 6 } h={ 6 } marginRight="3" />
                  Tulosta
                </Button>

              </Box>
            </Flex>

            <Box className="title-container" bg="bgColor" borderRadius="20px">
              <Text className="offer-title" textTransform="uppercase">{ condominium.name } { apartmentNumber }</Text>
              <div class="persons" style={{display:"flex",flexWrap:"wrap"}}>
                  { this.state.quote && this.state.quote.persons && this.state.quote.persons.map(person => {
                    return (
                      <div className="person">
                        <Text fontSize="xs" textTransform="uppercase">{person.title}</Text>
                        <Text color="gray.lighter" fontSize="md" fontWeight="bold">{ person.name }</Text>
                        <Text color="gray.lighter" fontSize="md" fontWeight="bold">{ I18n.t('materialforms.telephone') } { person.phone } </Text>
                      </div>)
                    })
                  }
              </div>
            </Box>
            <MaterialOfferIntro />
            <MaterialOfferGeneralInformation
              submissionForm={ stateSubmissionForm }
              onChangeCallback={ (name, value) => this.onChangeCallback(name, value) }
              comments={ materialQuoteComments }
              saveCommentCallback={ (payload) => this.saveCommentCallback(payload) }
              disabled={ disabled }
            />

            {stateSubmissionForm.material_quote.additional_rows && <>
              <Box mt="4" px={ [0, null, 8, 4, 8] } pb={ [0, null, 8, 4, 8] } pt={ [0, null, 8, 4, 8] } border={ { base: "none", md: "1px solid" } } borderColor="gray.inputBorder">
                <Heading>{ I18n.t('materialforms.additional_rows') }</Heading>
                { stateSubmissionForm.material_quote.additional_rows.map(row => {
                  return (
                    <Box border="3px solid" borderColor="gray.lightest" mb="4">
                      { !this.props.disabled &&
                        <Flex alignItems="center" justify="space-between" px={ [2, 4, 2, 4] } py="2" borderBottom="2px solid" borderColor="gray.lightest">
                          <Heading>{ row.title }</Heading>
                        </Flex>
                      }
                      <Flex direction={ { base: "column", md: "row" } }>
                        <Text py="2" px={ [2, 4, 2, 4] } w={ { base: "100%", md: "20%" } } fontWeight="bold"></Text>
                        <Flex py="2" px={ [2, 4, 2, 4] } flex="1" direction={ { base: "column", md: "row" } }>
                          <Text ml={ { base: "none", md: "2" } }>{ row.description }</Text>
                        </Flex>
                        <Box w={ { base: "100%", md: "20%" } } bg="bgColor">
                          <Text py="2" px={ [2, 4, 2, 4] }>{ I18n.t('general.price_total') } { this.getAdditionalRowPrice(row) } €
                            <Text>{ I18n.t('materialforms.labor_price') } { this.getRowLaborPrice(row) } €</Text>
                          </Text>
                        </Box>
                      </Flex>
                    </Box>
                  )
                }) }
              </Box>
            </>}

            <MaterialOfferChoiceListing
              key={ this.state.key }
              submissionForm={ stateSubmissionForm }
              selections={ materialSelections }
              quoteRows={ materialQuoteRows }
              comments={ quoteCommentRows }
              onChangeCallback={ (name, value) => this.onChangeCallback(name, value) }
              saveCommentCallback={ (payload, rowId) => this.saveQuoteRowCommentCallback(payload, rowId) }
              disabled={ disabled }
            />

            { allPhotos && allPhotos.length > 0 &&
              <>
                <Box mt="4" px={ [0, null, 8, 4, 8] } pb={ [0, null, 8, 4, 8] } pt={ [0, null, 8, 4, 8] } border={ { base: "none", md: "1px solid" } } borderColor="gray.inputBorder">
                  <Heading>{ I18n.t('materialforms.attachments') }</Heading>
                  { allPhotos && allPhotos.length > 0 &&
                    allPhotos.map((photo) => {
                      console.log(allPhotos);
                      return (
                        <Box mt={2}>
                          <Link onClick={() => {this.openAttachment(photo.image)}} key={photo.id} >
                            <Icon as={BsFileEarmarkText} w={4} h={4} color="black" marginRight="2"/>
                            <strong>{photo.name}</strong>
                          </Link>
                        </Box>
                      )
                    })
                  }
                </Box>
              </>
            }

            {/* Additional info */}
            <Box mt="4" px={ [0, null, 8, 4, 8] } pb={ [0, null, 8, 4, 8] } pt={ [0, null, 8, 4, 8] } border={ { base: "none", md: "1px solid" } } borderColor="gray.inputBorder">
              <Heading>{ I18n.t('materialforms.additional_info') }</Heading>
              <Flex alignItems="center">
                <Text py="2" w={ { base: "100%", md: "25%" } } fontWeight="bold">
                  { I18n.t('materialforms.completion_date') }
                </Text>
                <Text>{ this.state.quote.completion_date }</Text>
              </Flex>
              {!this.state.quote.completion_date && <Text>-</Text>}
              <Text pt="2" w={ { base: "100%" } } fontWeight="bold">
                { I18n.t('materialforms.additional_quote_info') }
              </Text>
              <Text>{ this.state.quote.additional_info ? this.state.quote.additional_info : '-' }</Text>
            </Box>

            <Box mt="4" px={ [0, null, 8, 4, 8] } pb={ [0, null, 8, 4, 8] } pt={ [0, null, 8, 4, 8] } border={ { base: "none", md: "1px solid" } } borderColor="gray.inputBorder">
              <Flex alignItems="center" border="3px solid" justify="end"  borderColor="gray.darkGray" mb="4" px={ [2, 4, 2, 4] } py="4">
                <Flex alignItems="center">
                  <Heading style={{paddingRight:"15px"}}>{ I18n.t('materialforms.labor_cost') }</Heading>
                  <Text fontWeight="bold">{ this.getWorkPriceTotal() } €</Text>
                </Flex>
                <Heading style={{padding:"0 15px"}}>|</Heading>
                <Flex alignItems="center">
                  <Heading style={{paddingRight:"15px"}}>{ I18n.t('materialforms.total') }:</Heading>
                  <Text fontWeight="bold">{ this.getTotalPrice() } { I18n.t('materialforms.includes_vat') }</Text>
                </Flex>
              </Flex>
              {/*<Text fontWeight="bold">
                { I18n.t('materialforms.calculations_disclaimer') }
              </Text>*/}
            </Box>

            <Flex mt="4" position="relative">
              <Button m="1" onClick={ this.props.disable } variant="grey-forward-btn">{ I18n.t('materialforms.button_cancel') }</Button>

              <Button m="1" onClick={ this.accept } variant="green-btn">
                  { I18n.t('materialforms.accept-quote') }
                  <Icon as={ BsArrowBarUp } display={ { base: "none", xl: "block" } } w={ 6 } h={ 6 } color="white" />
              </Button>

              { disabled
                ? ''
                : materialQuoteHasComments
                  ?
                  <Button m="1" onClick={ this.sendCorrections } variant="green-btn">
                    { I18n.t('materialforms.submit-corrections') }
                    <Icon as={ BsArrowBarUp } display={ { base: "none", xl: "block" } } w={ 6 } h={ 6 } color="white" />
                  </Button>
                  :
                  <></>
              }

              { this.state.quote.deadline && this.state.quote.deadline !== '0000-00-00 00:00:00' &&
                <Alert
                  placeContent={ { base: "center", md: "none" } }
                  py="0"
                  w={ { base: "100%", md: "200px" } }
                  status="error"
                  position="absolute"
                  right="0"
                  bottom={ [-8, -10, -4, -8] }
                  top={ { base: "none", md: "0" } }
                >

                  <AlertTitle pb="4" alignSelf="end">
                    { I18n.t('materialforms.accept-offer-before') } <Moment format="DD.MM.YYYY hh:mm">{ this.state.quote.deadline }</Moment>
                  </AlertTitle>
                </Alert>
              }
            </Flex>
          </Box>
        }
      </>
    )
  }
}


export default withRouter(MaterialFormOffer);
