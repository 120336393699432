/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import { NavLink, withRouter } from 'react-router-dom';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import AvainiaCore from 'avainia-core-api';
import { v4 as uuidv4 } from 'uuid';
import I18n from 'i18n-js';

//Chakra
import { Box, Divider, Text, Link, Heading, Flex, SimpleGrid, GridItem, Center } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';

//Icons
import { BsEnvelope } from 'react-icons/bs';
import { Spinner } from '@chakra-ui/spinner';

function compare(a, b) {
  if (a.created_at > b.created_at) {
    return -1;
  }
  if (a.created_at < b.created_at) {
    return 1;
  }
  return 0;
}

class NotificationsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      condominiumNotifications: [],
      apartmentNotifications: [],
      condominium: {},
      apartment: {},
      loading: true,
    };
  }

  componentDidMount = () => {
    const condominiumId = this.props.match.params.id;
    const apartment = LocalStorageService.getApartment();
    const apartmentId = apartment.id;
    const apartmentTemplateId = apartment.apartment_template_id;

    const api = new AvainiaCore(LocalStorageService.getToken);

    const promises = [
      api.condominiumGet(condominiumId, 'condominium'),
      api.condominiumApartmentGet(condominiumId, apartmentId, 'apartment'),
      api.condominiumNotificationsGet(condominiumId, 'condoNotification'),
      api.apartmentNotificationsGet(apartmentId, 'aptNotification'),
      api.condominiumApartmentTemplateMaterialformsWithSubmissionFormsGet(apartmentTemplateId, apartmentId, 'activeMaterialForms'),
      api.getOpenQuotesByApartment(apartmentId, 'openQuotes'),
    ];

    Promise.all(promises).then((data) => {
      let error = false;
      let condominium;
      let apartment;
      let condoNotification;
      let aptNotification;
      let openQuotes;
      let activeMaterialForms;

      data.forEach((x) => {
        if (error) { return; }
        if (x.condominium) { condominium = x.condominium; }
        if (x.apartment) { apartment = x.apartment; }
        if (x.condoNotification) { condoNotification = x.condoNotification; }
        if (x.aptNotification) { aptNotification = x.aptNotification; }
        if (x.openQuotes) { openQuotes = x.openQuotes; }
        if (x.activeMaterialForms) { activeMaterialForms = x.activeMaterialForms; }
      });

      if (error) { return this.setState({ loading: false, error }); }

      const condoArray = [];
      condoNotification.map((notification) => {
        condoArray.push(notification);
      });

      const aptArray = [];
      aptNotification.map((notification) => {
        aptArray.push(notification)
      });

      let deadlines = [];
      activeMaterialForms.forEach((form) => {
        // Each form should ever have only one submission.
        const submission = form && form.material_form_submissions && form.material_form_submissions[0];

        if (submission.status !== 'sent' && form.deadline != null) {
          deadlines.push(form.deadline)
        }
      })

      let quoteDeadlines = [];
      openQuotes.forEach((quote) => {
        if (quote.deadline != null && quote.status === 'sent') {
          quoteDeadlines.push(quote.deadline)
        }
      })

      this.setState({
          loading: false,
          condominium: condominium,
          apartment: apartment,
          deadlines,
          quoteDeadlines,
          activeMaterialForms,
          condominiumNotifications: condoArray,
          apartmentNotifications: aptArray,
      })
    })
  }

  downloadAttachment = async (notification) => {
    try {
      const obj = { headers: { Authorization: `Bearer ${LocalStorageService.getToken()}` } };
      const res = await fetch(process.env.REACT_APP_API_HOST + "/api/v1/notifications/" + notification.id + "/download", obj);
      const fileName = notification.attachment.name;

      if (res.ok) {
        const binary = await res.blob();

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(binary, fileName);
        } else {
          const src = window.URL.createObjectURL(binary);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style.cssText = 'display: none';
          a.href = src;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(src);
          setTimeout((x) => { document.body.removeChild(a); }, 1000);
        }
      } else if (res.status !== 410) { // 410 means dont retry
        throw new Error('failed to fetch document'); // TODO! Fatal error, do actual logging
      }
    } catch (ex) {
      console.error(ex); // TODO! Fatal error, do actual logging
    }
  }

  render() {
    const user = LocalStorageService.getUser();
    const userIsOwner = user.roles.filter((role) => role.display_name === 'apartmentOwner').length > 0;
    const { quoteDeadlines, deadlines } = this.state;
    const condoId = this.props.match.params.id;
    const apartment = LocalStorageService.getApartment();
    const aptId = apartment.id;

    return (
      <>
        {this.state.loading &&
          <Center>
            <Spinner size="xl" />
          </Center>
        }
        {!this.state.loading &&
          <>
          {userIsOwner && quoteDeadlines.map((deadline) => {
            const dateTime = deadline.split(" ");
            const date = dateTime[0];
            const time = dateTime[1];
            let receivedOffer = '.';
            if (date && date !== '0000-00-00') { // TODO check if this actually works
              const offerDlDate = moment(date).format("DD.MM.YYYY");
              const offerDlTime = moment(deadline).format("HH:mm");
              receivedOffer = (
                <>
                  {I18n.t('notifications.offer_deadline', {date: offerDlDate, time: offerDlTime})}
                </>
              );
            }

            return (
              <Box bg="base.yellow" p="4" mb="4">
                <Text fontWeight="bold">{I18n.t('notifications.received_offer')}{receivedOffer}</Text>
                <Link as={NavLink} to={`/condominium/${condoId}/${aptId}/materialforms`} variant="default-link"> {I18n.t('notifications.view_and_fill')}</Link>
              </Box>
            )
          })}
          {userIsOwner && deadlines.map((deadline) => {
            const dateTime = deadline.split(" ");
            const date = dateTime[0];
            const time = dateTime[1];
            const formDlDate = date;
            const formDlTime = time;

            return (
              <Box bg="base.yellow" p="4" mb="4">
                <Text fontWeight="bold">{I18n.t('notifications.materialform_deadline')}</Text>
                <Link as={NavLink} to={`/condominium/${condoId}/${aptId}/materialforms`} variant="default-link"> {I18n.t('notifications.view_and_fill')}</Link>
              </Box>
            )
          })}
            <Box p={[4, 8, 4, 8]} bg="white">
              <Heading mb={{ base: 6, xl: 0 }}>{I18n.t('notifications.notifications')} {this.state.condominium.name}</Heading>
              <Divider display={{ base: "none", lg: "block" }} />
              <SimpleGrid columns={{ base: 1, xl: 3 }}>
                <GridItem colSpan={1}>
                  <Flex mb={{ base: 8, xl: 0 }}>
                    <BsEnvelope size="28" />
                    <Heading px={2} placeSelf="center">
                      {`${this.state.apartment.stairwell}${this.state.apartment.apartment_number}`} {I18n.t('notifications.targetedNotifications')}
                    </Heading>
                  </Flex>
                </GridItem>
                <GridItem colSpan={2} ml={{ base: 10, lg: 0 }}>
                  {this.state.apartmentNotifications.sort(compare).map(notification => {
                    return (
                      <Flex key={uuidv4()} direction="column" pb={8}>
                        <Heading>
                          <Moment format="DD.MM.YYYY">{notification.created_at}</Moment> {notification.title}
                        </Heading>
                        {notification.attachment &&
                          <Link variant="default-link" mb={2} onClick={() => this.downloadAttachment(notification)} >{notification.attachment.name}</Link>
                        }
                        {notification.body &&
                          <Text>{notification.body}</Text>
                        }
                      </Flex>
                    );
                  })}
                </GridItem>
              </SimpleGrid>
              <Divider display={{ base: "none", lg: "block" }} />
              <SimpleGrid columns={{ base: 1, xl: 3 }}>
                <GridItem colSpan={1}>
                  <Flex mb={{ base: 8, xl: 0 }}>
                    <BsEnvelope size="28" />
                    <Heading px={2} placeSelf="center">
                      {I18n.t('notifications.condominiumNotifications')}
                    </Heading>
                  </Flex>
                </GridItem>
                <GridItem colSpan={2} ml={{ base: 10, lg: 0 }}>
                  {this.state.condominiumNotifications.sort(compare).map(notification => {
                    return (
                      <Flex key={uuidv4()} direction="column" pb={8}>
                        <Heading>
                          <Moment format="DD.MM.YYYY">{notification.created_at}</Moment> {notification.title}
                        </Heading>
                        {notification.attachment &&
                          <Link variant="default-link" my={1} onClick={() => this.downloadAttachment(notification)} >{notification.attachment.name}</Link>
                        }
                        {notification.body &&
                          <Text>{notification.body}</Text>
                        }
                      </Flex>
                    );
                  })}
                </GridItem>
              </SimpleGrid>
              <Center mt={4}>
                <Image src="/assets/images/splash-medium.png" alt="placeholder" />
              </Center>
            </Box >
            <Box
              display={["block", null, "none"]}
              position="relative"
              _before={{
                content: `""`,
                position: "absolute",
                width: "50px",
                height: "25px",
                bg: "base.yellow",
                bottom: "-25px",
              }}
              _after={{
                position: "absolute",
                content: `""`,
                left: "50px",
                height: "100px",
                width: "100px",
                background: "gray.decorationBox",
                bottom: "-125px",
              }}
            />
          </>
        }
      </>
    )
  }
}

export default withRouter(NotificationsPage);
