import React, { Component } from 'react';
import moment from 'moment';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import I18n from 'i18n-js';

//Chakra
import { Box, Divider, Flex, Heading, Link, Text } from '@chakra-ui/layout';
import { NavLink } from 'react-router-dom';

function compare(a, b) {
  if (a.created_at > b.created_at) {
    return -1;
  }
  if (a.created_at < b.created_at) {
    return 1;
  }
  return 0;
}
class NotificationBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { notifications, condoId, aptId, deadlines, quoteDeadlines } = this.props;
    const user = LocalStorageService.getUser();
    const userIsOwner = user.roles.filter((role) => role.display_name === 'apartmentOwner').length > 0;

    return (
      <Box bg="white" p={[4, 8, 4, 8]} mb="4">
        <Heading mb="4">{I18n.t('notifications.latest_notifications')}</Heading>
        {userIsOwner && quoteDeadlines.map((deadline) => {
          const dateTime = deadline.split(" ");
          const date = dateTime[0];
          const time = dateTime[1];
          let receivedOffer = '.';
            if (date && date !== '0000-00-00') { // TODO check if this actually works
              const offerDlDate = moment(date).format("DD.MM.YYYY");
              const offerDlTime = moment(deadline).format("HH:mm");
              receivedOffer = (
                <>
                  {I18n.t('notifications.offer_deadline', {date: offerDlDate, time: offerDlTime})}
                </>
              );
            }

          return (
            <Box bg="base.yellow" p="4" mb="4">
              <Text fontWeight="bold">{I18n.t('notifications.received_offer')}{receivedOffer}</Text>
              <Link as={NavLink} to={`/condominium/${condoId}/${aptId}/materialforms`} variant="default-link"> {I18n.t('notifications.view_and_fill')}</Link>
            </Box>
          )
        })}
        {userIsOwner && deadlines.map((deadline) => {
          const dateTime = deadline.split(" ");
          const date = dateTime[0];
          const time = dateTime[1];
          const formDlDate = date;
          const formDlTime = time;

          return (
            <Box bg="base.yellow" p="4" mb="4">
              <Text fontWeight="bold">{I18n.t('notifications.materialform_deadline')}</Text>
              <Link as={NavLink} to={`/condominium/${condoId}/${aptId}/materialforms`} variant="default-link"> {I18n.t('notifications.view_and_fill')}</Link>
            </Box>
          )
        })}
        <Box>
          {notifications.sort(compare).slice(0, 3).map(notification => {
            const createdDate = new Date(notification.created_at);
            return (
              <Flex mb="4">
                <Text fontWeight="bold" mr="10">{createdDate.toLocaleDateString("fi-FI")}</Text>
                <Text><strong>{notification.title}</strong><br />{notification.body.substring(0,100)}{notification.body.length > 100 && '...'}</Text>
              </Flex>
            )
          })}
        </Box>
        <Divider />
        <Box className="to-all-releases">
          <Link as={NavLink} to={`/condominium/${condoId}/${aptId}/notifications`} variant="default-link"> {I18n.t('notifications.all_notifications')}</Link>
        </Box>
      </Box>
    )
  }
}

export default NotificationBoard;
