export const en = {
  'accountrequest': {
    'name': 'Name*',
    'condominium': 'Condominium*',
    'residence': 'Residence / Residences*',
    'email': 'Email*',
    'phone': 'Phone number*',
    'message': 'Message',
    'send-request-btn': 'Send',
    'welcoming': 'Welcome to VRJ owner and resident portal',
    'portal_info': 'On the portal you can follow the progress of your condominium\'s renovation - newsletters, current affairs, schedules and documents related to the project. Through the portal, the owner can make material choices related to their own apartment.',
    'portal_responsibility': 'The renovation contractor VRJ is responsible for informing about the project and the contents of this portal.',
    'request_credentials': 'Request credentials on the portal',
    'request_instructions': 'Once the credentials has been approved by the Contractor, you will receive it in your email, after which you can log in to the portal.',
  },
  'avainiawrapper': {
    'apartment_review':'Apartment review {{apartment}}', // TODO check is 'katselmus' = 'review', 'inspection' etc.
    'apartment_review_time':'{{date}} at {{time}}',
    'print_and_fill':'Print and fill out the form for review:', // TODO check is 'katselmus' = 'review', 'inspection' etc. */
    'contact_and_mandate':'Contact information and mandate (pdf)', // TODO check if 'valtakirja' = 'mandate', 'letter of attorney' etc.
    'review_schedule_info':'We hope that the review can be held at the stated time, as the schedules have been drawn up taking into account the work steps and the people participating in the reviews will arrive for our meeting.',
    'review_time_not_fitting':'> If the viewing time doesn\'t fit despite of everything?',
    'your_apartment':'Your apartment',
  },
  'companypage': {
    'heading': 'VRJ - We Build Your Environment',
    'links': {
      'default': 'General',
      'environmentAndSecurity': 'Environment and security',
      'frequentQuestions': 'Frequent questions',
      'links': 'Useful links',
      'workSafety': 'Occupational safety',
    },
  },
  'frontpage': {
    'project_contacts': 'All project contacts',
  },
  'login': {
    'email': 'Email',
    'password': 'Password',
    'login-button': 'Login',
    'logout': 'Log out',
    'forgotten-password': 'Forgotten password?',
    'request-user': 'Request username for portal',
    'back-home': 'Back to front page',
    'login':'Log in to the portal',
  },
  'notifications': {
    'notifications': 'Notifications',
    'condominiumNotifications': 'Condominium notifications',
    'targetedNotifications': 'targeted notifications',
    'received_offer':'You have received an offer that should be accepted',
    'offer_deadline':' by {{time}} on {{date}}.',
    'materialform_deadline':'You have a material selection form which should be completed by {{time}} on {{date}}.',
    'view_and_fill':'View and fill',
    'latest_notifications':'Latest notifications',
    'all_notifications':'All notifications',
  },
  'projectmaterials': {
    'main-folder': 'Main folder',
    'heading': 'Project materials',
  },
  'contacts': {
    'contactPersons': 'Contact persons during line renovation',
  },
  'schedules': {
    'schedules': 'Schedules',
    'all-schedules': 'All schedules',
    'button-create': 'Create',
    'button-cancel': 'Cancel',
    'button-save': 'Save',
    'edit-schedule': 'Edit schedule',
    'create-schedule': 'Create new schedule',
    'create-schedule-description': 'Description',
    'create-schedule-start_at': 'Starts',
    'create-schedule-end_at': 'Ends',
    'create-schedule-estimate_start_at': 'Estimated starttime',
    'create-schedule-estimate_end_at': 'Estimated endtime',
    'create-schedule-parent_id': 'Parent schedule',
    'create-schedule-completion': 'Completion level',
    'schedule': 'Schedule',
    'name': 'Name',
    'start': 'Start',
    'end': 'End',
    'is-opening': 'opens',
    'is-closing': 'closes',
    'estimate-start': 'Estimated starttime',
    'estimate-end': 'Estimated endtime',
    'completed': 'Completion level',
    'expand_schedule':'Expand schedule',
    'upcoming_schedules':'Upcoming schedules',
  },
  'materialforms': {
    'products_total_price': 'Products total price',
    'button_cancel': 'Cancel',
    'button_save': 'Save',
    'button_save_and_quit': 'Save and quit',
    'residents_stay_during_repairs': 'Residents will be living in during the renovations',
    'info_background': 'The apartment has been renovated over the last three years. What? When?',
    'info_keep': 'Is there furniture in the bathroom that you want to keep?',
    'info_keep_reinstall': 'What furniture will be reinstalled after storage?',
    'info_keep_store': 'What furniture is stored (recovered, not reinstalled)?',
    'info_keep_store_instructions': 'Please note that the contractor is not obligated to store or take care of this furniture or equipment. They should be taken out of the apartment or moved out of the construction site immediately. The products are removed approximately two weeks after the start of work. Please let us know when you come to pick them up.',
    'general_instructions': 'General instructions',
    'background_information': 'Background information',
    'header_stored_or_reinstalled': 'Stored or reinstalled products',
    'saved': 'Saved',
    'previous_page': 'Previous',
    'next_page': 'Continue',
    'linked_materialforms': 'Linked material forms',
    'material_choices': 'Material choices',
    'total': 'Total',
    'get_mail':'You will get a email after aktivate',
    'approved':'after badge have been approved you can sing in portal',
    'contact_peoples':'all the projects contact people',
    'input_error':'Error - check the input.',
    'selection_form':'Material selection form',
    'modifying_choices':'The material selection form awaits your selections. The first one after saving, you can modify the choices you made until you send the form forward from the Send options button.',
    'making_choices':'The selection of materials has been completed and you have sent them forward. Wait until you get the offer approved.',
    'the_offer':'The offer is currently being made based on the selections you have made by. Wait for the offer to be completed.',
    'offer_ready':'Making the offer has been completed. See the offer and accept it by the final approval date. Before that, you can comment on the offer, at which point it will be reassessed.',
    'offer_accepted':'You have accepted the offer. It will be done based on the offer apartment card.',
    'aparment_card':'The apartment card is currently being made as you have done based on choices and offers. Wait for the card graduation.',
    'card_completed':'Making the apartment card has been completed. Look apartment card and accept it by the deadline.',
    'material_coiches':'The selection of materials has been completed. You can download apartment card. The apartment card serves as a guide as the apartment renovation progresses.',
    'effect_color':'Effect color',
    'doing_choices':'make choice',
    'first_last':'Firstname Lastname',
    'companypage_heading':'companypage heading',
    'selected': 'Selected',
    'selected_commented': 'Selected, Commented',
    'product_number': 'Product number:',
    'product_model': 'Manufacturer:',
    'product_name': 'Product:',
    'product_size': 'Size:',
    'product_prize': 'Prize:',
    'product_description': 'Description',
    'contract_prize': 'Contract price',
    'additional_price': 'Additional price',
    'view_product': 'View product',
    'package_size': 'Package size',
    'estimate_square_meter': 'Estimation of required square meterage: ',
    'estimate_final_price': 'Final prize calculations will be done in check-up.',
    'comments_for_this_product': 'Comments about the product:',
    'remove_selection': 'Remove selection',
    'select_product': 'Select product',
    'cancel': 'Cancel',
    'choose_one_product': 'Choose one product',
    'send-materialform': 'Send material choices',
    'send-materialform-confirm': 'Once you have sent your choices, they cannot be modified.',
    'edit_choices': 'Edit choices',
    'view_choices': 'View choices',
    'make_choices': 'Make choices',
    'statuses': {
      'open': 'Open',
      'closed': 'Closed',
      'sent': 'Sent',
      'accepted': 'Accepted',
      'declined': 'Rejected',
      'pending': 'Pending {target}',
      'ready': 'Ready'
    },
    'pending-target': {
      'sending': 'your form', // TODO: make better translation
      'quote': 'quote',
      'accepting': 'acceptance',
      'room-card': 'room card'
    },
    'open-offer': 'View offer',
    'accept-offer': 'View and accept offer',
    'download-room-card': 'Download room card',
    'layout': 'Layout',
    'telephone': 'Tel. ',
    'offer': 'Offer',
    'owner': 'Owner',
    'tenant': 'Tenant',
    'accept-offer-before': 'Accept offer before ',
    'deadline-passed': 'Late',
    'submit-corrections': 'Submit corrections',
    'comment': 'Comment',
    'accept-quote': 'Accept offer',
    'products': 'Products',
    'additional_products': 'Additional products',
    'additional_info': 'Additional info',
    'choose_multiple_products': 'Choose multiple products',
    'accept-quote-confirm': 'Are you sure you want to accept the offer? Once accepted, it can no longer be changed.',
    'accept-room-card-confirm': 'Are you sure you want to accept the room card? Once accepted, it can no longer be changed.',
    'pcs': 'pieces',
    'packages': 'packages',
    'labor_price': 'Labor price',
    'labor_cost': 'Labor cost:',
    'includes_vat': '€ Incl. VAT 24%',
    'additional_rows': 'Additional rows',
    'contractual':'Contractual',
    'extra_charge':'Extra charge',
    'photos':'Photos',
    'summary':'Summary of material choices',
    /* TODO check if 'menekkilaskenta' = 'sales calculation', 'demand calculation' etc. and if 'katselmus' = 'check-up', 'inspection', 'review' etc. */
    'calculations_disclaimer':'Please note that the automatic sales calculation, modifications and additional work are carried out carefully through the check-up and any changes will be corrected on the material selection form prior to bidding.',
    'submit_selections':'Submit selections',
    'choice_closes_on':'The material selection closes on',
    'housing_base':'Housing base',
    'image_caption':'Caption can be added to the image',
    'form_name':'Form name',
    'form_status':'Status',
    'form_deadline':'Deadline',
    'previous_comments':'Previous comments',
    'consumption': 'Consumption:', // TODO check 'menekki' translation
    'comments_for_general_info':'Comments for general information',
    'general_info':'General information',
    'residents_staying_during_repairs':'Are residents living during the renovation',
    'repairs':'Repairs', // TODO check if 'remontti' = 'repair' or 'renovation'?
    'keep_furniture':'Furniture to be stored',
    'store_furniture':'What is stored',
    'reinstall_furniture':'What will be reinstalled',
    'room':'Room:',
    'bathroom':'bathroom', // Notice: not capitalized
    'wall_tiles':'Wall tiles',
    'primary_color':'Primary color',
    'product_code':'Product code',
    'product_images':'Product images',
    'name':'Name', // TODO generalize 'name'?
    'area_price': 'price / m²',
    'floor_estimate':'room floor estimate {{estimate}}m²',
    'shareholder_estimate':'shareholder estimate {{estimate}}m²',
    'floor_tiles':'Floor tiles',
    'seam_colors':'Seam colors',
    'status': 'Tila',
    'deadline': 'Deadline',
    'additional_quote_info': 'Additional details on the quote',
    'completion_date': 'Additional time reservation',
    'active-phase': 'Active phase',
    'phases': {
      'materialform': 'Material changes',
      'quote': 'Quote phase',
      'room-card': 'Room card',
    },
    'status_instructions': 'Instructions in phase',
    'print': {
      'form': 'Print document',
      'quote': 'Print quote',
      'room-card': 'Print room card',
    },
    'accept-room-card': 'Accept room card',
  },
  'navigation': {
    'frontpage': 'Frontpage',
    'materialSelection': 'Material selection',
    'notifications': 'Notifications',
    'projectMaterials': 'Project materials',
    'schedule': 'Schedule',
    'company': 'Company',
    'contact': 'Contact',
    'switchApartment': 'Switch apartment',
    'offerReceived': 'You have received an offer',
    'condosAndApartments': 'Condominiums and apartments',
    'condominium':'Condominium',
  },
  'general': {
    'price_total': 'Total',
    'yes': 'Yes',
    'no': 'No',
    'close' : 'Close',
    'welcome': 'Welcome',
    'dev_project_list':'Your projects listed for dev purposes:',
    'no_apartment':'No apartment',
    'subpages':'Subpages',
    'thank_you': 'Thank you',
    'back':'Back',
  },
  calendar: {
    week: 'Week',
    months: {
      'january': 'January',
      'february': 'February',
      'march': 'March',
      'april': 'April',
      'may': 'May',
      'june': 'June',
      'july': 'July',
      'august': 'August',
      'september': 'September',
      'october': 'October',
      'november': 'November',
      'december': 'December',
    },
    shortMonths: {
      'jan': 'Jan',
      'feb': 'Feb',
      'mar': 'Mar',
      'apr': 'Apr',
      'may': 'May',
      'jun': 'Jun',
      'jul': 'Jul',
      'aug': 'Aug',
      'sep': 'Sep',
      'oct': 'Oct',
      'nov': 'Nov',
      'dec': 'Dec',
    },
    shortWeekdays: {
      'su': 'su',
      'mo': 'mo',
      'tu': 'tu',
      'we': 'we',
      'th': 'th',
      'fr': 'fr',
      'sa': 'sa',
    },
    weekdays: {
      'sunday': 'Sunday',
      'monday': 'Monday',
      'tuesday': 'Tuesday',
      'wednesday': 'Wednesday',
      'thursday': 'Thursday',
      'friday': 'Friday',
      'saturday': 'Saturday',
    },
  },
};

export const fi = {
  'accountrequest': {
    'name': 'Oma nimi*',
    'condominium': 'Taloyhtiö*',
    'residence': 'Asunto / Asunnot*',
    'email': 'Sähköposti*',
    'phone': 'Puhelin*',
    'message': 'Viesti',
    'send-request-btn': 'Lähetä',
    'welcoming': 'Tervetuloa VRJ:n osakas- ja asukasportaaliin',
    'portal_info': 'Portaalissa voi seurata oman taloyhtiön remontin etenemistä - tiedotteita, ajankohtaisia asioita, aikatauluja sekä hankkeeseen liittyviä dokumentteja. Portaalin kautta osakas voi tehdä omaan huoneistoon liittyvät materiaalivalinnat.',
    'portal_responsibility': 'Remontin urakoitsija VRJ vastaa hankkeen tiedottamisesta ja tämän portaalin sisällöistä.',
    'request_credentials': 'Pyydä tunnukset portaaliin',
    'request_instructions': 'Kun tunnus on hyväksytty Urakoitsijan toimesta, saat sen sähköpostiisi, jonka jälkeen voit kirjautua portaaliin.',
    'usertype': 'Olen',
    'usertype_owner': 'Osakas',
    'usertype_tenant': 'Vuokralainen',
  },
  'avainiawrapper': {
    'apartment_review':'Huoneistokatselmus {{apartment}}',
    'apartment_review_time':'{{date}} klo {{time}}',
    'print_and_fill':'Tulosta ja täytä lomake katselmukseen:',
    'contact_and_mandate':'Yhteystiedot ja valtakirja (pdf)', // TODO check if 'valtakirja' = 'mandate', 'letter of attorney' etc.
    'review_schedule_info':'Toivomme, että katselmus voidaan pitää ilmoitettuna ajankohtana, koska aikataulut on laadittu huomioiden työvaiheet ja katselmuksiin osallistuvat henkilöt saapuvat tapaamistamme varten paikalle.',
    'review_time_not_fitting':'> Jos katselmusaika ei kaikesta huolimatta sovi?',
    'your_apartment':'Oma asuntosi',
  },
  'companypage': {
    'heading': 'VRJ RAKENNUS - osa suurempaa kokonaisuutta!',
    'links': {
      'default': 'Yleinen',
      'environmentAndSecurity': 'Ympäristö',
      'frequentQuestions': 'Usein meiltä kysyttyä',
      'links': 'Hyödyllisiä linkkejä',
      'workSafety': 'Turvallisuus',
    },
  },
  'frontpage': {
    'project_contacts': 'Kaikki projektin yhteyshenkilöt',
  },
  'forgottenPassword': {
    'send-request-btn': 'Lähetä',
    'email': 'Sähköposti*',
    'title': 'Syötä sähköpostiosoitteesi',
    'subtitle': 'Sinulle lähetetään linkki salasanan nollaamiseen.',
    'thanks-1': 'Kiitos!',
    'thanks-2': 'Saat viestin sähköpostiisi jossa on ohjeet salasanan palauttamiseksi.'
  },
  'login': {
    'email': 'Sähköposti',
    'password': 'Salasana',
    'login-button': 'Kirjaudu',
    'logout': 'Kirjaudu ulos',
    'forgotten-password': 'Unohditko salasanasi?',
    'request-user': 'Pyydä tunnukset portaaliin',
    'login':'Kirjaudu portaaliin',
    'back-home': 'Takaisin etusivulle',
  },
  'notifications': {
    'notifications': 'Ilmoitukset',
    'condominiumNotifications': 'Taloyhtiökohtaiset ilmoitukset',
    'targetedNotifications': 'Kohdistetut ilmoitukset',
    'received_offer':'Olet saanut tarjouksen, joka tulisi hyväksyä',
    'offer_deadline':' {{date}} klo {{time}} mennessä.',
    'materialform_deadline':'Sinulle on materiaalien valintalomake. Täytä se materiaalivalinta osiossa.',
    'view_and_fill':'Katso ja täytä',
    'latest_notifications':'Uusimmat tiedotteet',
    'all_notifications':'Kaikki tiedotteet',
  },
  'projectmaterials': {
    'main-folder': 'Pääkansio',
    'heading': 'Urakkakansio / projektiaineistot',
  },
  'contacts': {
    'contactPersons': 'Linjasaneerauksen aikaiset yhteyshenkilöt'
  },
  'schedules': {
    'schedules': 'Aikataulut',
    'all-schedules': 'Kaikki aikataulut',
    'button-create': 'Luo',
    'button-cancel': 'Peruuta',
    'button-save': 'Tallenna',
    'edit-schedule': 'Muokkaa aikataulua',
    'create-schedule': 'Luo uusi aikataulu',
    'create-schedule-description': 'Kuvaus',
    'create-schedule-start_at': 'Alkaa',
    'create-schedule-end_at': 'Päättyy',
    'create-schedule-estimate_start_at': 'Arvioitu alkamisaika',
    'create-schedule-estimate_end_at': 'Arvioitu päättymisaika',
    'create-schedule-parent_id': 'Ylätason tunniste',
    'create-schedule-completion': 'Valmiusaste',
    'schedule': 'Aikataulu',
    'name': 'Nimi',
    'start': 'Aloitus',
    'end': 'Lopetus',
    'is-opening': 'alkaa',
    'is-closing': 'päättyy',
    'estimate-start': 'Arvioitu aloitusaika',
    'estimate-end': 'Arvioitu lopetusaika',
    'completed': 'Valmiusaste',
    'expand_schedule':'Suurenna aikataulu',
    'upcoming_schedules':'Tulevat aikataulut',
  },
  'materialforms': {
    'products_total_price': 'Yhteensä',
    'button_cancel': 'Peruuta',
    'button_save': 'Tallenna',
    'button_save_and_quit': 'Tallenna ja lopeta',
    'residents_stay_during_repairs': 'Huoneistossa asutaan remontin aikana',
    'info_background': 'Huoneistossa on viimeisen kolmen vuoden aikana tehty remonttia. Mitä? Milloin?',
    'info_keep': 'Onko kylpyhuoneessa sellaisia kalusteita, jotka haluatte säilyttää?',
    'info_keep_reinstall': 'Mitä kalusteita asennetaan takaisin säilytyksen jälkeen?',
    'info_keep_store': 'Mitä kalusteita säilytetään (otetaan talteen, ei asenneta takaisin)?',
    'info_keep_store_instructions': 'Huomioithan, että urakoitsija ei ole velvollinen varastoimaan tai huolehtimaan näistä kalusteista tai varusteista. Ne kannattaa viipymättä viedä pois asunnosta tai siirtää pois työmaa-alueelta. Tuotteet ovat irrotettuina n. kaksi viikkoa töiden aloittamisen jälkeen. Ilmoitathan, kun tulet noutamaan niitä.',
    'general_instructions': 'Yleiset ohjeet',
    'background_information': 'Taustatietoja huoneistosta',
    'header_stored_or_reinstalled': 'Säilytettävät tai takaisin asennettavat tuotteet',
    'saved': 'Tallennettu',
    'previous_page': 'Edellinen',
    'next_page': 'Jatka eteenpäin',
    'linked_materialforms': 'Materiaalivalintojen tekeminen',
    'material_choices': 'Materiaalivalinnat',
    'total': 'Yhteensä',
    'get_mail': 'Saat viestin sähköpostiisi kun tunnuksesi on aktivoitu.',
    'approved':'Kun tunnus on hyväksytty Urakoitsijan toimesta, saat sen sähköpostiisi, jonka jälkeen voit kirjautua portaaliin',
    'contact_peoples':'Kaikki projektin yhteyshenkilöt',
    'input_error':'Virhe - Tarkista syöte.',
    'selection_form':'Materiaalien valintalomake',
    'modifying_choices':'Materiaalivalintalomake odottaa valintojasi. Ensimmäisen tallennuksen jälkeen voit muokata tekemiäsi valintoja, kunnes lähetät lomakkeen eteenpäin Lähetä valinnat -painikkeesta.',
    'making_choices':'Materiaalivalintojen tekeminen on valmistunut ja olet lähettänyt ne eteenpäin. Odota kunnes saat tarjouksen hyväksyttäväksi.',
    'the_offer':'Tarjousta ollaan parhaillaan tekemässä tekemiesi valintojen perusteella. Odota tarjouksen valmistumista.',
    'offer_ready':'Tarjouksen tekeminen on valmistunut. Katso tarjous ja hyväksy se viimeiseen hyväksyntäpäivään mennessä. Sitä ennen voit kommentoida tarjousta, jolloin se lähtee uudelleenarvioitavaksi. Jos valinnoillasi on kustannusvaikutuksia, hyväksynnän jälkeen saat vielä sähköpostitse linkin sähköistä allekirjoitusta varten.',
    'offer_accepted':'Olet hyväksynyt tarjouksen. Tarjouksen perusteella tehdään huoneistokortti.',
    'aparment_card':'Huoneistokorttia ollaan parhaillaan tekemässä tekemiesi valintojen ja tarjousten perusteella. Odota kortin valmistumista. Saat valmistumisesta ilmoituksen sähköpostiisi.',
    'card_completed':'Huoneistokortin tekeminen on valmistunut. Katso huoneistokortti ja hyväksy se määräaikaan mennessä.',
    'material_coiches':'Materiaalivalintojen tekeminen on valmistunut. Voit ladata huoneistokortin. Huoneistokortti toimii ohjeena huoneistoremontin edetessä.',
    'area_price': 'hinta / m²',
    'effect_color':'Tehosteväri',
    'doing_choices':'Tee valinnat',
    'first_last':'etunimi sukunimi',
    'companypage_heading':'Kaikki projektin yhteyshenkilöt',
    'selected': 'Valittu',
    'selected_commented': 'Valittu, Kommentoitu',
    'product_number': 'Tuotemerkki:', // TODO check if 'product number' = 'tuotemerkki'
    'product_model': 'Valmistaja:',
    'product_name': 'Tuote:',
    'product_size': 'Koko:',
    'product_prize': 'Hinta:',
    'product_description': 'Kuvaus',
    'contract_prize': 'Urakanmukainen',
    'additional_price': 'Lisähinta',
    'view_product': 'Katso tuote',
    'package_size': 'Pakkauskoko',
    'estimate_square_meter': 'Arviosi laattaseinän neliömäärästä: ',
    'estimate_final_price': 'Tarkastuslaskenta tehdään katselmuksessa.',
    'comments_for_this_product': 'Kommentit tuotteeseen liittyen:',
    'remove_selection': 'Poista valinta',
    'select_product': 'Valitse tuote',
    'cancel': 'Peruuta',
    'choose_one_product': 'Valitse 1 tuote',
    'send-materialform': 'Lähetä materiaalinvalinnat',
    'send-materialform-confirm': 'Kun olet lähettänyt tekemäsi valinnat, niitä ei enää voi muokata.',
    'edit_choices': 'Muokkaa valintoja',
    'view_choices': 'Näytä valinnat',
    'make_choices': 'Täytä lomake',
    'statuses': {
      'open': 'Avoin',
      'closed': 'Suljettu',
      'sent': 'Lähetetty',
      'accepted': 'Hyväksytty',
      'declined': 'Hylätty',
      'pending': 'Odottaa {{target}}',
      'ready': 'Valmis'
    },
    'pending-target': {
      'sending': 'lähetystäsi',
      'quote': 'tarjousta',
      'accepting': 'hyväksyntääsi',
      'room-card': 'huoneistokorttia'
    },
    'open-offer': 'Näytä tarjous',
    'accept-offer': 'Tarkista ja hyväksy tarjous',
    'download-room-card': 'Lataa huoneistokortti',
    'layout': 'Asuntopohja',
    'telephone': 'Puh. ',
    'offer': 'Tarjous',
    'owner': 'Osakas',
    'tenant': 'Vuokralainen',
    'accept-offer-before': 'Hyväksy tarjous ennen ',
    'deadline-passed': 'Myöhässä',
    'submit-corrections': 'Lähetä korjaukset',
    'comment': 'Kommentoi',
    'accept-quote': 'Hyväksy tarjous',
    'labor_price': 'Työn osuus',
    'products': 'Tuotteet',
    'additional_products': 'Lisätuotteet',
    'additional_info': 'Lisätiedot',
    'choose_multiple_products': 'Valitse useita tuotteita',
    'accept-quote-confirm': 'Oletko varma, että haluat lähettää tarjouksen? Kun tarjous on hyväksytty, sitä ei voi enää muuttaa.',
    'accept-room-card-confirm': 'Oletko varma, että haluat hyväksyä huoneistokortin? Kun huoneistokortti on hyväksytty, sitä ei voi enää muuttaa.',
    'pcs': 'kpl',
    'packages': 'pakettia',
    'labor_cost': 'Työn osuus:',
    'includes_vat': '€ Sis. ALV 24%',
    'additional_rows': 'Lisärivit',
    'contractual':'Urakanmukainen',
    'extra_charge':'Lisähintainen',
    'photos':'Kuvat',
    'attachments':'Tarjouksen liitteet',
    'summary':'Yhteenveto materiaalivalinnoista',
    'calculations_disclaimer':'Huomioithan, että automaattinen menekkilaskenta, muutos- ja lisätyöt käydään huolellisesti läpi katselmuksessa ja mahdolliset muutokset korjataan materiaalien valintalomakkeelle ennen tarjouksen tekemistä.',
    'submit_selections':'Lähetä valinnat',
    'choice_closes_on':'Materiaalivalinta sulkeutuu',
    'housing_base':'Asuntopohja',
    'image_caption':'Kuvateksti voidaan lisätä kuvan yhteyteen',
    'comment_offer':'Kommentoi tarjousta',
    'comment_offer_notice':'Huomioithan, että jos kommentoit, tarjousta ei voi enää hyväksyä vaan se lähtee uudelle tarjouskierrokselle, jonka jälkeen Urakoitsija lähettää korjatun tarjouksen hyväksyttäväksi.',
    'enter_comment_here':'Kirjoita tähän tarkennukset tai korjauspyynnöt tarjoukseen tähän',
    'form_name':'Lomakkeen nimi',
    'form_status':'Status',
    'form_deadline':'Viimeinen hyväksyntäpäivä',
    'previous_comments':'Aiemmat kommentit',
    'consumption': 'Menekki:', // TODO check 'menekki' translation
    'comments_for_general_info':'Kommentit yleistä tietoa varten',
    'general_info':'Yleiset tiedot',
    'residents_staying_during_repairs':'Asutaanko remontin aikana',
    'repairs':'Remontit', // TODO check if 'remontti' = 'repair' or 'renovation'?
    'keep_furniture':'Säilytettäviä kalusteita',
    'store_furniture':'Mitä säilytetään',
    'reinstall_furniture':'Mitä asennetaan takaisin',
    'room':'Tila:',
    'bathroom':'kylpyhuone', // Notice: not capitalized
    'wall_tiles':'Seinälaatat',
    'primary_color':'Pääväri',
    'product_code':'Tuotekoodi',
    'product_images':'Tuotekuvat',
    'name':'Nimi', // TODO generalize 'name'?
    'area_price': 'hinta / m²',
    'floor_estimate':'huonepohja-arvio {{estimate}}m²',
    'shareholder_estimate':'osakkaan arvio {{estimate}}m²',
    'floor_tiles':'Lattialaatat',
    'seam_colors':'Saumavärit',
    'additional_quote_info': 'Lisätarkennuksia tarjoukseen',
    'completion_date': 'Lisäaikavaraus',
    'active-phase': 'Menossa oleva vaihe',
    'phases': {
      'materialform': 'Muutosvalinnat',
      'quote': 'Tarjousvaihe',
      'room-card': 'Huoneistokortti',
    },
    'status_instructions': 'Ohje vaiheessa',
    'print': {
      'form': 'Tulosta lomake',
      'quote': 'Tulosta tarjous',
      'room-card': 'Tulosta huoneistokortti',
    },
    'accept-room-card': 'Hyväksy huoneistokortti',
  },
  'navigation': {
    'frontpage': 'Etusivu',
    'materialSelection': 'Materiaalivalinta',
    'notifications': 'Tiedotteet',
    'projectMaterials': 'Projektiaineistot',
    'schedule': 'Aikataulut',
    'company': 'Yritys',
    'contact': 'Yhteystiedot',
    'switchApartment': 'Vaihda asunto',
    'offerReceived': 'Sinulle on lähetetty tarjous',
    'condosAndApartments': 'Taloyhtiöt ja huoneistot',
    'condominium':'Taloyhtiö',
  },
  'general': {
    'price_total': 'Yhteensä',
    'yes': 'Kyllä',
    'no': 'Ei',
    'close': 'Sulje',
    'welcome': 'Tervetuloa',
    'dev_project_list':'Your projects listed for dev purposes:',
    'no_apartment':'Ei asuntoa',
    'subpages':'Alasivut',
    'back':'Takaisin',
    'thank_you':'Kiitos'
  },
  calendar: {
    week: 'Viikko',
    months: {
      'january': 'Tammikuu',
      'february': 'Helmikuu',
      'march': 'Maaliskuu',
      'april': 'Huhtikuu',
      'may': 'Toukokuu',
      'june': 'Kesäkuu',
      'july': 'Heinäkuu',
      'august': 'Elokuu',
      'september': 'Syyskuu',
      'october': 'Lokakuu',
      'november': 'Marraskuu',
      'december': 'Joulukuu',
    },
    shortMonths: {
      'jan': 'Tam',
      'feb': 'Hel',
      'mar': 'Maa',
      'apr': 'Huh',
      'may': 'Tou',
      'jun': 'Kes',
      'jul': 'Hei',
      'aug': 'Elo',
      'sep': 'Syy',
      'oct': 'Lok',
      'nov': 'Mar',
      'dec': 'Jou',
    },
    shortWeekdays: {
      'su': 'su',
      'mo': 'ma',
      'tu': 'ti',
      'we': 'ke',
      'th': 'to',
      'fr': 'pe',
      'sa': 'la',
    },
    weekdays: {
      'sunday': 'Sunnuntai',
      'monday': 'Maanantai',
      'tuesday': 'Tiistai',
      'wednesday': 'Keskiviikko',
      'thursday': 'Torstai',
      'friday': 'Perjantai',
      'saturday': 'Lauantai',
    },
  },
};


const translations = { en, fi };

export default translations;
